import React from 'react'
import { Datagrid, DateField, TextField } from 'react-admin'
import { SubscriptionDeleteButton } from './SubscriptionDeleteButton'

export const SubscriptionDatagrid = (props) => (
  <Datagrid rowClick="show">
    <TextField source="id" />
    <TextField source="profileId" />
    <TextField source="state" />
    <TextField source="type" />
    <TextField source="platform" />
    <DateField source="expirationDate" label="Expiration date" />
    <TextField source="token" />
    <TextField source="productId" />
    <SubscriptionDeleteButton {...props} />
  </Datagrid>
)
