import React from 'react'
import { Datagrid, DateField, TextField } from 'react-admin'

export const UserDatagrid = () => (
  <Datagrid rowClick="show">
    <TextField source="id" />
    <TextField source="phone" label="Phone number" />
    <DateField source="createdAt" label="Creation date" />
  </Datagrid>
)
