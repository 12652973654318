import React from 'react'
import { Datagrid, List, TextField, DateField, Filter, TextInput } from 'react-admin'
import { ListActionsCreateExport } from '../../config/components'

const AccountFilter = (props) => (
  <Filter {...props}>
    <TextInput label="phone" source="phone" alwaysOn />
  </Filter>
)

const UserList = (props) => {
  return (
    <>
      <List
        {...props}
        actions={<ListActionsCreateExport />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<AccountFilter />}
      >
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="phone" label="Phone number" />
          <DateField source="createdAt" label="Creation date" />
        </Datagrid>
      </List>
    </>
  )
}

export default UserList
