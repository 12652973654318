import { ListActionsCreateExport } from '../../config/components'
import React from 'react'
import { List } from 'react-admin'
import { SubscriptionDatagrid } from './SubscriptionDatagrid'

export const SubscriptionList = (props) => {
  return (
    <>
      <List {...props} actions={<ListActionsCreateExport />} bulkActionButtons={false}>
        <SubscriptionDatagrid {...props} />
      </List>
    </>
  )
}
